<template>

  <div  style="width: 100%;background-color: #fff;min-height: 650px;height: auto;margin: 0 auto;">
<van-nav-bar
  title="管理"
  left-text="返回"
  left-arrow
  @click-left="onClickLeft"
>
	</van-nav-bar>
	 <van-row style="margin-top: 10%;">
	 	  <van-col span="24"></van-col>
	 </van-row>
	 
	 <van-row class="content-div" >
	 	  <van-col span="24" style="font-size: 20px">当前注册码:{{ root_code }}</van-col>
	 </van-row>


	 <van-row class="content-div"  gutter="30">
	 	  <van-col span="12">
			  <van-button @click="toRouteb()" block type="primary" native-type="submit" style="color: #fff;">
				初始设置
			  </van-button>
		  </van-col>
		  <van-col span="12">
			  <van-button @click="toOut()" block type="danger" native-type="submit" style="color: #fff;">
				重新登录
			  </van-button>
		  </van-col>
	 </van-row>

	 <van-row class="content-div"  gutter="30" v-if="is_top_admin">
	 	  <van-col span="12">
			  <van-button @click="toRoute()" block type="primary" native-type="submit" style="color: #fff;">
				暗码设置
			  </van-button>
		  </van-col>
		  <!-- <van-col span="12">
			  <van-button @click="toRoute('AdminpwSetup')" block type="primary" native-type="submit" style="color: #fff;">
				管理密码设置
			  </van-button>
		  </van-col> -->
	 </van-row>
  </div>
  
</template>

<script >
import { ref } from "vue";
import { Dialog } from 'vant';
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import axios from 'axios'
const visitorIdd = ref('');
//获取设备唯一标识
const getUniqueCode = () => {
    FingerprintJS.load().then(fp => {
        fp.get().then(result => {
            visitorIdd.value = result.visitorId;
            console.log('获取设备唯一标识：',visitorIdd.value);
        });
    });
}
   export default {
        data() {
           return {
                code:"",
                is_top_admin:0,
                root_code:0,
           } 
        },
        computed: {

        },
        mounted() {
            getUniqueCode()
            this.root_code = window.localStorage.getItem('root_code')
           // this.getdata ()
            if(window.localStorage.getItem('is_admin') != 1){
                this.$router.push({path: '/wehchat/adminvalidate'})
            } 
            if(window.localStorage.getItem('is_top_admin') == 1 ){
                this.is_top_admin =1
            }
            
        },

        methods: {
            toOut () {
            	window.localStorage.setItem('admin_code', '');
            	window.localStorage.setItem('is_top_admin', 0);
            	window.localStorage.setItem('is_admin', 0);     
            	this.$router.push({path: '/wehchat/adminvalidate'})
            },
        toRoute () {
            this.$router.push({path: '/wehchat/adminm'})
        },        
        toRouteb () {
            this.$router.push({path: '/self/settings/data'})
        },       
        onClickLeft () {
            this.$router.push({path: '/self/settings'})
        },
            getdata () {
        
            
            	if(!this.code) {
                          Dialog.confirm({
                            title: '',
                               message: '注册码不能为空',
                               confirmButtonText: '取消',
                               confirmButtonColor: '#1f517c',
                               cancelButtonText: '确定'
                           })
            		return;
            	}
            axios.post(this.$adminUrl+'/api/index/check_wxcode',{code:this.code,uid:visitorIdd.value})
            	.then((response) => {
            		//console.log('response',response)
            		if(response.data.code=='200'){
            			//sessionStorage.setItem('is_root', 1);
            			window.localStorage.setItem('is_root', 1);
            			window.localStorage.setItem('root_code',visitorIdd.value);
            			this.$router.push({path: '/'})
            	/*		router.push({
            				name: "Index",
            				query: {}
            			});*/
            		//	showSuccessToast("验证成功");
            			return;
            		}else{
            			window.localStorage.setItem('is_root', 0);
            			//showFailToast(rs.msg);
            			console.log(response.data.message)
                          Dialog.confirm({
                            title: '',
                               message: response.data.message,
                               confirmButtonText: '取消',
                               confirmButtonColor: '#1f517c',
                               cancelButtonText: '确定'
                           })
            		//	showFailToast("服务升级中，请联系客服");
            			return;
            		}
            	}).catch(error => {
            		console.log('error',error)
            		window.localStorage.setItem('is_root', 0);
            	//	showFailToast("服务升级中，请联系客服");
            		//console.error('Error checking file existence:', error);
            	});   
            },  	
        }

   }
</script>

<style scoped>
	.content-div{
		width: 80%;
		margin: 5% auto;
	}
</style>